[class*='primary'] { background-color: #957c64; border-color: #957c64; }
/* [class*='secondary'] { background-color: #957c64; border-color: #957c64; } */
[class*='black'] { background-color: #1d2429; border-color: #1d2429; }

/* ::-webkit-scrollbar {
    display: none;
} */

/* @media screen and (max-width: 768px){
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
} */

p {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.react-slideshow-container .default-nav {
    display: none !important;
}

.rdrDateDisplayWrapper {     
    display: none; 
}

.rdrDefinedRangesWrapper {
    display: none;
}

.rdrStartEdge {
    color: #957c64 !important
}
.rdrInRange {
    color: rgba(26,26,26,0.06) !important;
}
.rdrEndEdge {
    color: #957c64 !important
}
.rdrDayToday .rdrDayNumber span:after {
    background: #957c64 !important;
    display: none
}
.rdrDay {
    height: 4em;
    line-height: 4em;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    top: 1px;
    bottom: 1px
}
.rdrDayNumber {
    margin-top: -8px;
    font-size: 12px !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #1d2429
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber .rdrPriceInfo , .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber .rdrPriceInfo, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber .rdrPriceInfo {
    color: #ffffff !important
}

.slick-prev {
    left: -5px;
    z-index: 50;
    width: 30px;
    height: 30px;
}
.slick-next {
    right: -5px;
    z-index: 50;
    width: 30px;
    height: 30px;
}
.Toastify__toast-body {
    font-size: 14px;
    padding: 0px !important;
    width: 100%;
    font-weight: 400;
    margin-left: 15px !important;
}
.yarl__container {
    background-color: #000000CC;
}